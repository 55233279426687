import ClipboardJS from 'clipboard';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Slide, toast } from 'react-toastify';
import { Box, Button, Flex, Image, Link, Text } from 'rebass';
import iconCopyLink from '../assets/images/icon_copy_link.svg';
import iconValidateArtistLink from '../assets/images/icon_validate_artist_link.svg';
import '../styles/ReactToastify.css';
import AnimatedContainer from './AnimatedContainer';
import Hide from './Hide';

class BookingArtist extends PureComponent {
  constructor(props) {
    super(props);

    const { expandedMode } = this.props;

    this.state = {
      isBookingLinkHoverState: false,
      isExpanded: expandedMode,
      isHoverState: false,
    };
  }

  componentDidMount() {
    const { artist } = this.props;

    this.clipboardInstance = new ClipboardJS(`#clipboard-${artist.id}`);

    this.clipboardInstance.on('success', (e) => {
      toast(
        <Text
          color="white"
          css={{
            alignItems: 'center',
            display: 'flex',
          }}
          fontFamily="sans"
          fontSize={['11px', '11px', '14px']}
          fontWeight="normal"
          lineHeight={['14px', '14px', '24px']}
        >
          <Image
            height={['10px', '10px', '16px']}
            mr="7px"
            src={iconValidateArtistLink}
          />
          Artist URL copied
        </Text>,
        {
          autoClose: 2000,
          closeButton: false,
          closeOnClick: false,
          draggable: false,
          hideProgressBar: true,
          pauseOnHover: false,
          transition: Slide,
        },
      );

      e.clearSelection();
    });

    this.checkHash();

    window.addEventListener('hashchange', this.checkHash);
  }

  componentWillUnmount() {
    if (this.clipboardInstance) {
      this.clipboardInstance.destroy();
    }

    window.removeEventListener('hashchange', this.checkHash);
  }

  checkHash = () => {
    const {
      artist: { title },
    } = this.props;

    if (window.location.hash === `#${_.snakeCase(title)}`) {
      this.setState({
        isExpanded: true,
      });
    }
  };

  toggleExpand = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  getAgents = () => {
    const { artist, isManagementPage } = this.props;

    if (isManagementPage && artist.managementAgents?.length > 0) {
      return artist.managementAgents;
    }

    if (isManagementPage && artist.bookingAgents?.length > 0) {
      return artist.bookingAgents;
    }

    if (!isManagementPage && artist.bookingAgents?.length > 0) {
      return artist.bookingAgents;
    }

    return artist.managementAgents;
  };

  render() {
    const { artist, isManagementPage, expandedMode } = this.props;
    const { isBookingLinkHoverState, isExpanded, isHoverState } = this.state;
    const agents = this.getAgents();
    const attributes = isManagementPage
      ? artist.managementPageAttributes || artist.bookingPageAttributes
      : artist.bookingPageAttributes || artist.managementPageAttributes;

    return (
      <AnimatedContainer>
        <Box
          css={{
            maxWidth: '1500px',
          }}
          id={_.snakeCase(artist.title)}
          key={artist.id}
          mx="auto"
          px={['20px', '30px', '70px']}
        >
          <Flex
            css={{
              borderTop: '1px solid black',
            }}
            justifyContent="space-between"
            pb="25px"
            pt={['5px', '5px', '10px']}
          >
            <Flex flexDirection="column" width={['100%', '100%', 3 / 4]}>
              <Box
                as="button"
                css={{
                  alignSelf: 'flex-start',
                  backgroundColor: 'transparent',
                  border: 0,
                  cursor: 'pointer',
                }}
                onClick={this.toggleExpand}
                onPointerEnter={() => this.setState({ isHoverState: true })}
                onPointerLeave={() => this.setState({ isHoverState: false })}
                ml={[0, 0, '-7px']}
                p={0}
                type="button"
              >
                <Text
                  as="h1"
                  css={{
                    textAlign: 'left',
                    transform:
                      isExpanded || isHoverState
                        ? 'skewX(-10deg)'
                        : 'skew(0deg)',
                    transition: 'all 0.1s ease-in-out',
                  }}
                  fontFamily="sans"
                  fontSize={[34, 34, 88]}
                  letterSpacing={[-1, -1, -2.5]}
                  lineHeight={['40px', '40px', '88px']}
                  mb={10}
                  mt={0}
                  width="100%"
                >
                  {artist.title}
                </Text>
              </Box>
              {attributes.map((attribute) => (
                <Flex key={attribute.title}>
                  <Box minWidth={['100px', '100px', '120px']}>
                    <Text
                      css={{
                        textTransform: 'uppercase',
                      }}
                      fontFamily="sans"
                      lineHeight={['16px', '16px', '24px']}
                      fontSize={[11, 11, 14]}
                    >
                      {attribute.title}:
                    </Text>
                  </Box>
                  <Box>
                    <Text
                      css={{
                        textTransform: 'uppercase',
                      }}
                      fontFamily="sans"
                      lineHeight={['16px', '16px', '24px']}
                      fontSize={[11, 11, 14]}
                    >
                      {attribute.value}
                    </Text>
                  </Box>
                </Flex>
              ))}
              <Hide
                as="button"
                css={{
                  border: 'none',
                  cursor: 'pointer',
                  padding: 0,
                  position: 'relative',
                }}
                display={['flex', 'flex', 'none']}
                my={20}
                onClick={this.toggleExpand}
                onPointerEnter={() => this.setState({ isHoverState: true })}
                onPointerLeave={() => this.setState({ isHoverState: false })}
                p={0}
                type="button"
              >
                <div
                  style={{
                    width: '100%',
                    zIndex: 1,
                  }}
                >
                  <Img
                    fluid={artist.image.childImageSharp.fluid}
                    style={{ filter: 'grayscale(100%)' }}
                  />
                </div>
                <div
                  style={{
                    opacity: isExpanded || isHoverState ? 1 : 0.001,
                    position: 'absolute',
                    transition: 'all 0.1s ease-in-out',
                    width: '100%',
                    zIndex: 10,
                  }}
                >
                  <Img fluid={artist.image.childImageSharp.fluid} />
                </div>
              </Hide>
              <Flex flexWrap="wrap" mt="auto">
                <Button
                  as="a"
                  bg={isBookingLinkHoverState ? 'white' : 'black'}
                  border="1px solid"
                  borderColor="black"
                  borderRadius={0}
                  color={isBookingLinkHoverState ? 'black' : 'white'}
                  css={{
                    cursor: 'pointer',
                    transition: 'all 0.1s ease-in-out',
                  }}
                  href={
                    isManagementPage
                      ? `mailto:${agents[0].email}?subject=${artist.title}%20inquiry`
                      : `mailto:${agents[0].email}?subject=${artist.title}%20booking%20request`
                  }
                  mb={['5px', '5px', 0]}
                  mr={[0, 0, '10px']}
                  onPointerEnter={() =>
                    this.setState({
                      isBookingLinkHoverState: true,
                      isHoverState: true,
                    })
                  }
                  onPointerLeave={() =>
                    this.setState({
                      isBookingLinkHoverState: false,
                      isHoverState: false,
                    })
                  }
                  px="10px"
                  py={['13px', '13px', '4px']}
                  width={['100%', '100%', 'auto']}
                >
                  <Text
                    css={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      svg: {
                        transition: 'all 0.1s ease-in-out',
                      },
                    }}
                    fontFamily="sans"
                    fontSize={['11px', '11px', '14px']}
                    lineHeight={['14px', '14px', '24px']}
                  >
                    <svg
                      height="12"
                      style={{
                        marginRight: isBookingLinkHoverState ? '7px' : '0',
                      }}
                      viewBox="0 0 7 12"
                      width={isBookingLinkHoverState ? '7' : '0'}
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          transform="translate(3.500000, 6.000000) rotate(-90.000000) translate(-3.500000, -6.000000) translate(-2.000000, 3.000000)"
                          fill="#000000"
                        >
                          <polygon points="0 0 11 0 5.5 6" />
                        </g>
                      </g>
                    </svg>
                    {isManagementPage ? 'INQUIRY' : 'BOOKING REQUEST'}
                  </Text>
                </Button>
                {!expandedMode && (
                  <Button
                    bg={
                      isExpanded || (isHoverState && !isBookingLinkHoverState)
                        ? 'black'
                        : 'white'
                    }
                    border="1px solid"
                    borderColor="black"
                    borderRadius={0}
                    color={
                      isExpanded || (isHoverState && !isBookingLinkHoverState)
                        ? 'white'
                        : 'color'
                    }
                    css={{
                      cursor: 'pointer',
                      svg: {
                        fill:
                          isExpanded ||
                          (isHoverState && !isBookingLinkHoverState)
                            ? '#ffffff'
                            : '#000000',
                        transition: 'all 0.1s ease-in-out',
                      },
                      transition: 'all 0.1s ease-in-out',
                    }}
                    onClick={this.toggleExpand}
                    onPointerEnter={() => this.setState({ isHoverState: true })}
                    onPointerLeave={() =>
                      this.setState({ isHoverState: false })
                    }
                    px="10px"
                    py={['13px', '13px', '4px']}
                    width={['100%', '100%', 'auto']}
                  >
                    <Text
                      css={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      fontFamily="sans"
                      fontSize={[11, 11, 14]}
                      lineHeight={['14px', '14px', '24px']}
                    >
                      LEARN MORE
                      {isExpanded ? (
                        <svg
                          height="7"
                          fill="#ffffff"
                          style={{ marginLeft: '7px' }}
                          viewBox="0 0 10 7"
                          stroke="#ffffff"
                          width="10"
                        >
                          <defs>
                            <path id="jwu5a" d="M393 2944l4-4 4 4" />
                          </defs>
                          <g>
                            <g transform="translate(-392 -2938)">
                              <use
                                fillOpacity="0"
                                strokeMiterlimit="50"
                                strokeWidth="2"
                                // stroke="#fff"
                                xlinkHref="#jwu5a"
                              />
                            </g>
                          </g>
                        </svg>
                      ) : (
                        <svg
                          height="7"
                          style={{ marginLeft: '7px' }}
                          viewBox="0 0 10 7"
                          width="10"
                        >
                          <polygon
                            points="131.293 14.293 132.707 15.707 128 20.414 123.293 15.707 124.707 14.293 128 17.586"
                            transform="translate(-123 -14)"
                          />
                        </svg>
                      )}
                    </Text>
                  </Button>
                )}
              </Flex>
            </Flex>
            <Hide
              as="button"
              css={{
                border: 'none',
                cursor: 'pointer',
                padding: 0,
                position: 'relative',
              }}
              display={['none', 'none', 'flex']}
              mt={15}
              onClick={this.toggleExpand}
              onPointerEnter={() => this.setState({ isHoverState: true })}
              onPointerLeave={() => this.setState({ isHoverState: false })}
              p={0}
              type="button"
              width={1 / 4}
            >
              <div
                style={{
                  width: '100%',
                  zIndex: 1,
                }}
              >
                <Img
                  fluid={artist.image.childImageSharp.fluid}
                  style={{ filter: 'grayscale(100%)' }}
                />
              </div>
              <div
                style={{
                  opacity: isExpanded || isHoverState ? 1 : 0.001,
                  position: 'absolute',
                  transition: 'all 0.1s ease-in-out',
                  width: '100%',
                  zIndex: 10,
                }}
              >
                <Img fluid={artist.image.childImageSharp.fluid} />
              </div>
            </Hide>
          </Flex>
          {isExpanded && (
            <Flex
              flexDirection={['column-reverse', 'column-reverse', 'row']}
              flexWrap="wrap"
              justifyContent="space-between"
              pb="25px"
            >
              <Box width={['100%', '100%', 'calc(75% - 40px)']}>
                <AnimatedContainer>
                  <Text
                    css={{
                      borderTop: '1px solid black',
                    }}
                    fontFamily="sans"
                    fontSize={[18, 18, 19]}
                    mb="15px"
                    mt={['25px', '25px', '0']}
                    pt={15}
                  >
                    Biography
                  </Text>
                  <Text
                    css={{ whiteSpace: 'pre-line' }}
                    fontFamily="grot10"
                    fontSize={['16px', '16px', '21px']}
                    letterSpacing="-0.5px"
                    lineHeight={['22px', '22px', '25px']}
                  >
                    {artist.biography}
                  </Text>
                </AnimatedContainer>
                <AnimatedContainer>
                  <Text
                    css={{
                      borderTop: '1px solid black',
                    }}
                    fontFamily="sans"
                    fontSize={[18, 18, 19]}
                    mb="15px"
                    mt={['25px', '25px', '40px']}
                    pt={15}
                  >
                    Music
                  </Text>
                  {artist.musicWidget.type === 'spotify' ? (
                    <Box mb={['25px', '25px', '0']}>
                      <iframe
                        allow="encrypted-media"
                        frameBorder="0"
                        height="380"
                        src={`https://open.spotify.com/embed/artist/${artist.musicWidget.artistId}`}
                        title="Spotify"
                        width="100%"
                      />
                    </Box>
                  ) : (
                    <Box mb={['25px', '25px', '0']}>
                      <iframe
                        allow="autoplay"
                        frameBorder="no"
                        height="380"
                        scrolling="no"
                        src={artist.musicWidget.artistId}
                        title="Soundcloud"
                        width="100%"
                      />
                    </Box>
                  )}
                </AnimatedContainer>
                {artist.youtubeIframeUrl && (
                  <AnimatedContainer>
                    <Text
                      css={{
                        borderTop: '1px solid black',
                      }}
                      fontFamily="sans"
                      fontSize={[18, 18, 19]}
                      mb="15px"
                      mt={['25px', '25px', '40px']}
                      pt={15}
                    >
                      Video
                    </Text>
                    <Box>
                      <iframe
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        frameBorder="0"
                        height="500"
                        src={artist.youtubeIframeUrl}
                        title="YouTube"
                        width="100%"
                      />
                    </Box>
                  </AnimatedContainer>
                )}
              </Box>
              <Box width={['100%', '100%', 'calc(25%)']}>
                <AnimatedContainer>
                  <Box
                    css={{
                      borderTop: '1px solid black',
                    }}
                  >
                    {agents.map((agent, index) => (
                      <Box key={agent.email} mt={index > 0 ? [20, 20, 25] : 0}>
                        <Text
                          fontFamily="sans"
                          fontSize={[18, 18, 19]}
                          mb="15px"
                          pt={15}
                        >
                          {agent.title}
                        </Text>
                        <Link
                          color="black"
                          css={{
                            '&:hover': {
                              textDecorationColor: 'transparent',
                            },
                            textDecoration: 'underline',
                            textDecorationColor: 'black',
                            transition: 'all 0.1s ease-in-out',
                          }}
                          href={`mailto:${agent.email}`}
                        >
                          <Text
                            fontFamily="grot10"
                            fontSize={['16px', '16px', '21px']}
                            letterSpacing="-0.5px"
                            lineHeight={['22px', '22px', '25px']}
                          >
                            {agent.email}
                          </Text>
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </AnimatedContainer>
                {artist.pressKitUrl && (
                  <AnimatedContainer>
                    <Text
                      css={{
                        borderTop: '1px solid black',
                      }}
                      fontFamily="sans"
                      fontSize={[18, 18, 19]}
                      key={0}
                      mb="15px"
                      mt={['25px', '25px', '40px']}
                      pt={15}
                    >
                      Press Kit
                    </Text>
                    <StaticQuery
                      query={graphql`
                        query {
                          icon: file(relativePath: { eq: "press_kit.png" }) {
                            childImageSharp {
                              fluid {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                      `}
                      render={({ icon }) => (
                        <Link download href={artist.pressKitUrl}>
                          <Box
                            css={{
                              maxWidth: '220px',
                            }}
                          >
                            <Img fluid={icon.childImageSharp.fluid} />
                          </Box>
                        </Link>
                      )}
                    />
                  </AnimatedContainer>
                )}
                <AnimatedContainer>
                  <Text
                    css={{
                      borderTop: '1px solid black',
                    }}
                    fontFamily="sans"
                    fontSize={[18, 18, 19]}
                    mb="15px"
                    mt={['25px', '25px', '40px']}
                    pt={15}
                  >
                    Social
                  </Text>
                  {artist.socialLinks.length > 0 &&
                    artist.socialLinks.map((socialLink) => (
                      <Link
                        color="black"
                        css={{
                          '&:hover': {
                            textDecorationColor: 'transparent',
                          },
                          textDecoration: 'underline',
                          textDecorationColor: 'black',
                          transition: 'all 0.1s ease-in-out',
                        }}
                        key={socialLink.name}
                        href={socialLink.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Text
                          fontFamily="grot10"
                          fontSize={['16px', '16px', '21px']}
                          letterSpacing="-0.5px"
                          lineHeight={['22px', '22px', '25px']}
                        >
                          {socialLink.name}
                        </Text>
                      </Link>
                    ))}
                  <Box
                    as="button"
                    bg="transparent"
                    color="black"
                    css={{
                      '&:hover': {
                        textDecorationColor: 'transparent',
                      },
                      border: 0,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      textDecorationColor: 'black',
                      transition: 'all 0.1s ease-in-out',
                    }}
                    data-clipboard-text={`${
                      typeof window !== 'undefined' && window.location.origin
                    }/artists/${_.snakeCase(artist.title)}`}
                    id={`clipboard-${artist.id}`}
                    mt={artist.socialLinks.length > 0 ? [20, 20, 25] : 0}
                    px={0}
                    py={0}
                    to={`/${artist.title}`}
                    type="button"
                  >
                    <Text
                      fontFamily="grot10"
                      fontSize={['16px', '16px', '21px']}
                      fontWeight="normal"
                      letterSpacing="-0.5px"
                      lineHeight={['22px', '22px', '25px']}
                    >
                      <Image
                        height={[15, 15, 19]}
                        mr="7px"
                        src={iconCopyLink}
                      />
                      Copy artist URL
                    </Text>
                  </Box>
                </AnimatedContainer>
              </Box>
            </Flex>
          )}
        </Box>
      </AnimatedContainer>
    );
  }
}

BookingArtist.propTypes = {
  artist: PropTypes.shape({
    biography: PropTypes.string.isRequired,
    bookingAgents: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    ),
    bookingPageAttributes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ),
    id: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    managementAgents: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    ),
    managementPageAttributes: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
    ),
    musicWidget: PropTypes.shape({
      artistId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    pressKitUrl: PropTypes.string,
    socialLinks: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    title: PropTypes.string.isRequired,
    youtubeIframeUrl: PropTypes.string,
  }).isRequired,
  expandedMode: PropTypes.bool,
  isManagementPage: PropTypes.bool,
};

BookingArtist.defaultProps = {
  expandedMode: false,
  isManagementPage: false,
};

export default BookingArtist;
